:root {
--heroButton: #8093f1;
--heroButtonColor: #f4f4ed;
--bg-hero:url('./hero-bg.svg');
--position:center top;
}
[data-theme = "dark"] {
    --heroButton: #9fddfe;
    --heroButtonColor: #000;
    --bg-hero:url('./hero-bg-dark.svg');
    --ignore:url('./Bite-background1.jpg');
    --position:center center;
}

.pg-hero {
  min-height:calc(100vh - 20px);
  max-height:1000px;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
.hmHeroBg {  
  background:var(--bg-hero); 
  background-size: cover;
}
.sobig {font-size: 50px;}
.techLogo {max-height:100px;}

@media screen and (max-width: 100px) {
  .pg-hero {
    height:70vh;
  }
}


/*RESPONSIVE WINDOWS RESPONSIVE WINDOWS*/
@media screen and (max-width:650px) {
  .pg-hero {
    padding: 20px 10px 30px;
  }
  .techLogo {max-height:50px; }
}