.toggle input {
  visibility: hidden;
}

.toggle + label {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction:row-reverse;
  grid-gap:10px;
  justify-content:space-between;
  font-size:25px;
}

.toggle + label::before {
  content: "";
  height: 0px;
  width: 0px;
  border-radius: 50px;
  background: var(--toggle);
  transition: background-color 250ms ease-in-out;
}

.toggle + label::after {
  content: "";
  height: 0px;
  width: 0px;
  border-radius: 20px;
  background-color: var(--bg-pg);
  position: absolute;
  margin-right:3px;
  transition: background-color 250ms ease-in-out, transform 250ms ease-in-out;
}

.toggle:checked + label::after {
  transform: translateX(-20px);
}

/*alignment*/
.flex{display:flex;}
.flcenter {justify-content:center} 

@media screen and (max-width: 1000px) {
  .toggle + label {
    font-size: 20px;
  }
}