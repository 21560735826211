.menuContainer {
  display: flex;
  flex-direction:row;
  margin-bottom:10px;
  position:sticky;
  top:0;
  transition: 1s ease;
  z-index:999;
  background-color:var(--bg-pg);
  padding:10px 40px 10px 32px;
  box-shadow: 1px 1px 3px #9fddfe66;
}
.logo {
  width:10%;
}
.blogo {
  height:40px!important;
  width:auto;
  font-family:Rosario;
}
.text {
  font-family:Rosario;
  letter-spacing:1px;
  font-size:30px;
  font-weight:700;
  fill:var(--tx-color);
}
.slogo line {stroke:var(--tx-color); stroke-width:1.5;}
.slogo .black {fill:var(--tx-color);}
.slogo .white {fill:var(--bg-pg);}
.menuItemsWrapper {
  width:70%;
}
.menuItems {
  display: flex;
  flex-direction:row;
  list-style-type:none;
  justify-content: center;
  border-radius:50px;
  background-color:#9FDDFE22;
  padding:12px 0;
  visibility: hidden;
}
.menuItems a {
  text-decoration:none;
  padding: 12px 20px;
  margin:0 10px;
  color:var(--tx-color);
  font-family:Rosario;
  font-size:18px;
}
.menuItems a:active {
  background-color:var(--Bite-blue);
  color:#000;
  border-radius:30px;
}
.menuItems a:hover {
  background-color:var(--Bite-blue);
  color:#000;
  border-radius:30px;
}

.MenuRightContact {
  width:20%;
  text-align:right;
}
.menuContact {
  padding: 12px 40px;
  color:#000;
  font-size:18px;
  background-color:var(--Bite-blue);
  border-radius:30px;
  font-family:Rosario;
  cursor:pointer;
}
.menuContact:hover {
 padding:12px 30px;
 transition: 0.5s ease;
}

@media screen and (max-width:1000px) {
.menuContainer {
  margin-bottom:10px;
  position:fixed;
  top:0;
  width:95vw;
  padding:10px 10px;
}
.menuItems {display:none;}
.menuItemsWrapper {width:30%;}
.MenuRightContact {width:40%;}
.logo {width:30%;}
.menuContact {
  padding: 7px 10px;
  font-size:15px;
  cursor:pointer;
}
.menuContact:hover {
 padding:7px 10px;
}
.text {font-size:30px; font-weight:500;}
}