.myAlert .swal2-title {
  font-family: Rosario;
  color:var(--tx-color); /* Replace with your font color */
}

#swal2-html-container {
  font-family: Nunito;
  color:var(--tx-color); /* Replace with your font color */
}

.myAlert .swal2-confirm {
  background-color: #9fddfe; /* Replace with your button color */
  color: #000; /* Replace with your button text color */
}

.swal2-popup {
  background-color:var(--bg-swal); /* Replace with your background color */
}
/*swal2-popup swal2-modal myAlert swal2-icon-success swal2-show*/