
* {
        margin: 0;
        padding: 0;
        border: 0;
    }
@font-face {
  font-family: "Rosario";
  src: url('./fonts/Rosario/Rosario-VariableFont_wght.ttf') format("truetype");
}
@font-face {
  font-family: "Nunito";
  src: url('./fonts/Nunito/Nunito-VariableFont_wght.ttf') format("truetype");
}
.ros {font-family: Rosario;}
.nun {font-family: Nunito;}

:root {
--bg-pg:#f4f4ed;
--tx-color:#000;
--bg-ap:#e8eaed;
--stroke:none;
--btn-stroke:#8093f1;
--Bite-blue:#9fddfe;
--social-icons:#000;
--bg-swal:#f4f4ed;
--toggle:hsl(230, 17%, 14%);
--opacity:0;
--counter-bg:#8093f120;
--counters:#e8eaed;
}
[data-theme="dark"] {
--bg-pg:#2f2f2f;
--tx-color:#f4f4ed;
--bg-ap:#333;
--stroke:none;
--btn-stroke:#9fddfe;
--social-icons:#9fddfe;
--bg-swal:hsl(230, 17%, 14%);
--toggle:linear-gradient(to right top, #8093f1, #9fddfe);
--opacity:0;
--counter-bg:#9fddfe20;
--counters:#252525;
}
body {
  overflow-x:hidden!important;
}
.BiteApp {
  display:flex;
}
/* APP MENU.                APP MENU                APP MENU.                        APP MENU.       */
.appMenu {
  display:flex;
  flex-direction:column;
  background-color:var(--bg-ap)/*rgba(200,200,200,0.5)*/;
  height:100vh;
  position:fixed;
  width: 80px;
  text-align:center;
  color:var(--tx-color);
  font-family:Rosario;
  z-index: 1001;
  align-items:center;
  justify-content: space-between;
}
.appIcon {
  font-size: 25px; 
  font-weight:100;
  padding-top: 6px;
}
.appMenuItems {
  display:flex;
  flex-direction:column;
}
.toggle {font-size:10px;} .toggle a {font-size:25px;} 
.appMenuItems a {
  padding:10px;
  margin:10px;
  text-decoration:none;
  pointer:cursor;
  color:var(--tx-color);
  font-size:14px;
}
.appMenuItems a:hover .appIcon, .appMenuItems a.active .appIcon {
  background-color:var(--Bite-blue);
  border-radius:20px;
  color:#000000;
}
.bottomAligned {padding-bottom: 30px;}


/*. SUB MENU        SUB MENU              SUB MENU               SUB MENU           SUB MENU*/
.subMenu {
  position:absolute;
  top:10px;
  left: 90px;
  height: calc(80vh);
  width: 250px;
  background-color:var(--bg-ap);
  border-radius:20px;
  padding: 50px 20px;
  transform: translateX(-450px);
  z-index:-10;
  display:none;
  overflow-y: scroll;
}
#servicebutton:hover, #subMenu {
  display: ;
  transform: translateX(0px);
  transition: 0.3s ease-in-out;
}
.subMenu a {
  padding:0 10px;
  margin:0;
}
.dropdown .content {
  display:none;
}
.dropdown, .content {
  display:block;
  transition:0.3s ease-in-out;
}

/*      APP SCREEEN            APP SCREEN                   APP SCREEN                 APP SCREEN */
.appScreen {
  margin-left: 80px;
  width:calc(100vw - 80px);
  background-color:var(--bg-pg);
  height: auto;
  padding: 0 0px;
  color:var(--tx-color);
  justify-content:center;
}
.appScreen h2, h3 {
  font-family:Rosario;
  font-size:40px;
  font-weight:500;
}
.noMenu {padding:10px;}
.appScreen h1, h2, h3, h4, h5, h6 {font-family: Rosario;}
.actual {max-width:1440px;}
section {padding:0 40px;}

/* BUTTON           BUTTON                   BUTTON                 BUTTON.   */
.contactButton {
  font-family:Rosario;
  font-size:25px;
  padding:15px 50px;
  background-color:#9fddfe;
  border: none;
  color:#000;
  cursor: pointer;
  border-radius: 50px;
}
.contactButton:hover {
  dashoffset: 100;
  padding:15px 40px;
  text-align:center;
  transition:0.5s ease;
}
.buttonArrow {
  stroke:#000;
  stroke-dasharray:100;
  stroke-dashoffset:100;
  animation: draw 2s infinite alternate;
}

/* FONTS                     FONTS                     FONTS                              FONTS   */
p {
  font-family:Nunito;
  font-size:20px;
  line-height:40px;
}
a {
  text-decoration: none;
}
.bigHeading {
  font-size: 40px;
  padding:20px 0;
  font-family: Rosario;
  font-weight:500;
}
.smHeading {
  font-family:Rosario;
  font-size:25px;
  font-weight:500;
}
.boldHeader {
  font-family:Rosario;
  font-size:52px;
  font-weight:600;
  text-align:center;
  padding: 0px 0  20px;
}
.bigText {
  font-size: 25px; 
  text-align:center; 
  width:60%; 
  padding:0 20%;
}
.capitalize {text-transform: capitalize;} .uppercase {text-transform: uppercase;} .lowercase {text-transform: lowercase;}
/*FONT-SIZES                 FONT-SIZES                      FONT-SIZES                       FONT-SIZES */
.f100 {font-size: 100px} .f90 {font-size: 90px} .f80 {font-size: 80px;} .f70 {font-size: 70px;} .f50 {font-size: 50px;} 
.f30 {font-size: 30px;} .f25{font-size:25px} .f20 {font-size: 20px;} .f17{font-size:17px;} .f15 {font-size: 15px;}



/* FONT-SIZES                 FONT-SIZES                      FONT-SIZES                       FONT-SIZES */


/* CARDS              CARDS              CARDS         */
.card {
  border-radius:20px; 
  padding:20px 30px;
  background-color:var(--bg-pg) ;
}
.card:hover {
  background-color: ;
}
.gridBox {border-radius:10px; padding:20px;} .gridBox:hover{background-color:; box-shadow:2px solid #333}
.r10 {border-radius: 10px;} .r20 {border-radius: 20px;} .r50 {border-radius: 50px;}
.round {border-radius: 50%;}


/*General column sections              GENERAL COLUMN  SECTIONS                   GENERAL COLUMN SECTIONS */
.columns {
  display:flex;
  flex-direction:row;
  align-items:center;
}
.row {
  display:flex;
  flex-direction: column;
  align-items:;
}
.rreverse {flex-direction: column-reverse;} .reverse {flex-direction: row-reverse;}
.columns50, .w50 {width: 50%;} .columns3, .w3{width:33.3%;} .columns60, .w60{width:60%} 
.columns70, .w70 {width:70%} .columns30, .w30{width:30%} .columns40, .w40 {width:40%} 
.columns25, .w25{width:25%} .columns100, .w100 {width:100%}

.grid {display:;} .grid10 {grid-gap:10px;} .grid20 {grid-gap:20px;} .grid50 {grid-gap:50px;}.grid100 {grid-gap:100px;}

.sticky10 {
  position:sticky;
  top:10px;
}

.widePad {padding:200px 0;}
.pad {padding:50px 0;}

.border {
  border-top: 1px solid var(--bg-pg);
  border-bottom: 1px solid var(--bg-pg);
}
.borderTop {border-top: 1px solid #999}
.dHide {display:none;}

/* HEIGHTS           HEIGHTS              HEIGHTS              HEIGHTS                HEIGHTS*/
.h80 {height: 80vh;} .h50 {height:50vh} .h90 {height:90vh} .h100 {height:100vh}


/*Backgrounds.       BACKGROUNDS              BACKGROUNDS                     BACKGROUNDS           BACKGROUNDS*/
.gradientBg {
  background: url("./gradient.svg");
  background-position: center;
  //background: radial-gradient(circle farthest-corner at 25% 50%, rgba(128,147,241,0.3) 0%, rgba(159,221,254,0.3) 10%, rgba(244,244,237,1) 60%);
}
.gradientBg.dark {
  /*background: radial-gradient(farthest-corner at 25% 60%, rgba(128,147,241,0.2) 0%, rgba(159,221,254,0.1) 20%, rgba(31,31,31,1) 60%);*/
  background:none;
}
.blueBg{background-color:#9fddfe; color:#000;} 
.purpleBg{background-color:#8093f1; color:#f4f4ed;} 
.lavBg{background-color:#b388eb;}



/*Paddings.      PADDINGS                       PADDINGS                           PADDINGS*/
.padLeft50 {padding-left:50px;}
.centralHero {padding:0 10%}
.p10 {padding:10px} .p20 {padding:20px} .p50 {padding:50px}
.pt0 {padding-top:0;} .pb0 {padding-bottom:0;} .p0 {padding:0;}
.pt5 {padding-top: 5px;} .pt10 {padding-top: 10px;}
.m0 {margin:0;}
.down {margin-bottom: -5px;}
.mspacer {padding-top:0px;}


/*Spacers*/
.spacer50 {padding-top:50px} .spacer20 {padding-top:20px} .spacer30 {padding-top:30px} .spacer100 {padding-top:100px}


/*alignment          ALIGNMENT                     ALIGNMENTS                     ALIGNMENT*/
.center {text-align:center} .right {text-align:right} .left {text-align:left} 
.flex {display:flex;}
.flcenter {justify-content:center} .vcenter{align-items:center;}
.start {align-items:flex-start;} .end {align-items: flex-end;}
.spaceBetween {justify-content: space-between;} .spaceAround {justify-content: space-around;}


/*colors                 COLORS                        COLOURS                               COLOURS */
.purple {color:#8093f1} .blue {color:var(--Bite-blue)}

/*Footer              FOOTER                             FOOTER                          FOOTER  */
.footerH {font-family:Rosario; font-size:20px;}
.footerP {font-size:15px; line-height:25px;}
.footerGridBox {padding:0 0 0 50px; align-items:flex-start; justify-content:flex-start;}
.footerGridBox a {text-decoration:none;}
.footerGridBox .smHeading  {font-family:Rosario; font-weight: 500; font-size:18px; padding:25px 0 5px 0;}
.footerSmP {font-size:14px; line-height:20px;}
.footerIcons {font-size:30px; color:#8093f1;}
.social {font-size:25px; color:var(--social-icons);} .social a {color:var(--social-icons);} .social a:hover {color:#8093f1; transition:0.5s ease;}
.divItem {padding:0 10px 10px 10px; border-radius:10px; color:var(--tx-color);}
/*.divItem a {color:var(--tx-color);}*/
.divItem:hover {background-color:#9fddfe33;}
.copyright{font-size:10px; padding:5px 0; color:#000; background-color:var(--Bite-blue); font-family:Nunito;}


/*Animations              ANIMATIONS                     ANIMATIONS                          ANIMATIONS*/
.spin { 
  animation:fa-spin 3s ease infinite;
  animation-delay:0s;
  animation-timing-function: ease-in-out;  
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.slideIn {
	-webkit-animation: wpr-anim-text-slide-in 0.6s;
	animation: wpr-anim-text-slide-in 0.6s;
}
@keyframes wpr-anim-text-slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
		-ms-transform: translateY(20%);
		transform: translateY(20%);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
}






/* RESPONSIVE                     RESPONSIVE                        RESPONSIVE               RESPONSIVE  */

@media screen and (max-width:1000px) {
.BiteApp {
  flex-direction:column-reverse;
}
/* APP MENU              APP MENU                      APP MENU                        APP MENU*/
.appMenu {
  display:flex;
  flex-direction:row;
  height:10vh;
  position:fixed;
  width: 100%;
  text-align:center;
  bottom:0;
  grid-gap:10vw;
  width:100%;
  z-index:10;
  box-sizing:border-box;
  border-top:3px solid #9fddfe;
}
.appIcon {font-size:20px}
.appMenuItems {
  display:flex;
  flex-direction:row;
}
.appMenuItems a {
  padding:10px;
  margin:10px;
}
.bottomAligned {padding-bottom: 0; padding-right:15px;}

/* APP SCREEN                APP SCREEN                   APP SCREEN                      APP SCREEN. */
.appScreen {
  margin-left:0;
  width:100%;
  max-width:100%;
  overflow-y:scroll;
  display:flex;
  justify-content:center;
  padding: 0 0;
}
.actual {max-width:99vw; overflow-y:scroll; height:auto;}
section {padding:0px 15px;}
.columns {flex-direction:column;}
.slider {animation: slide 20s linear infinite;}

/* FONTS                FONTS                   FONTS                           FONTS*/
.bigHeading {
  font-size:25px;
  padding:10px 10px;
  font-family: Rosario;
  font-weight:500;
}
.boldHeader {
  font-size:25px;
  font-weight:500;
  padding: 0;
  text-align:left;
}
.smHeading {
  font-size:20px;
  font-weight:500;
}
p {
  font-family:Nunito;
  font-size:18px!important;
  line-height:30px!important;
}

.mf25 {font-size:25px;} .mf30 {font-size: 30px;} .mf50 {font-size: 50px;}
.mp20 {padding:20px;}
.mgrid0 {grid-gap:0px}



/* BUTTON                       BUTTON                       BUTTON                         BUTTON*/
.contactButton {
  font-size:20px;
  padding:10px 20px;
}
.contactButton:hover {
  padding:10px 20px;
  transition:1s ease-in-out;
}

/* GENERAL COLUMN SECTIONS                 GENERAL COLUMN SECTIONS                   GENERAL COLUMN SECTIONS */
.pad {padding:10px 0;}
.mreverse {flex-direction:column-reverse;} .reverse {flex-direction:;}
.mnopad {padding:0;} .dHide {display:block} .mHide {display:none;} .mcolumns{display:flex; flex-direction:row;grid-gap:40px;padding:10px 0; margin:0;}
.mcenter {text-align:center; justify-content:center;} .mleft {text-align:left; justify-content:left;} .mspacer {padding-top:20px;}
/*Columns*/
.columns50, .columns3, .w3, .columns60, .w60, .columns70, .w70, .columns30, .w30, .columns40, 
.columns25, .columns100{width: 100%;}
.w50 {width:auto;}
.grid50, .grid100 {grid-gap:20px;}

/*Paddings              PADDINGS              PADDINGS.                PADDINGS          */
.padLeft50 {padding-left:0px;}
.centralHero {padding:0 5%}

/*Spacers*/
.spacer50 {padding-top:30px} .spacer20 {padding-top:20px} .spacer30 {padding-top:20px} .spacer100 {padding-top:20px}


/*footers           FOOTER            FOOTER                  FOOTER                 FOOTER*/
.footerGridBox {padding:0; align-items:top;}
.footerP {font-size:12px; line-height:20px;}
.footerSmP {font-size:10px; line-height:15px;}
.footerGridBox .smHeading  {font-size:18px; padding:5px 0 5px 0;}
.footerH {font-weight:600;}

}






/* RESPONSIVE iMAC             RESPONSIVE    LARGE SCREENS             RESPONSIVE IMAC*/
@media screen and (min-width:1900px) {
.appScreen {display:flex;}
}