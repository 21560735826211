:root {
  --heroButton: #8093f1;
  --heroButtonColor: #f4f4ed;
  --position:center top;
  --bg-hero:url('./hero-bg.svg');
}
[data-theme = "dark"] {
  --heroButton: #9fddfe;
  --heroButtonColor: #000;
  --bg-hero:url('./hero-bg-dark.svg');
  --position:center center;
}
.hero h1 {
  font-family:Rosario;
  font-weight: 500;
  font-size:60px;
  letter-spacing: 2px;
  padding-right:15%;
}
.hero p {
  padding-top:50px;
  font-weight:400;
  font-size:30px;
  padding-right:40%;
}
.hero {
  padding:10px 50px 0;
  border-radius:50px;
  display:flex;
  background:var(--bg-hero);
  background-position: center center;
  color:var(--tx-color);
  height:500px;
  background-size: cover;
}
.heroButton {
  background-color: var(--heroButton);
  color: var(--heroButtonColor);
  stroke: var(--heroButtonColor);
}
.heroLeft {
  padding:50px 0;
}
.parallax {
  padding-top:0vh;   
}
.bg1 {
  background-position-y:35%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment:fixed;
  opacity:var(--opacity);
}
.bg2 { 
  opacity:var(--opacity);
  background-size: contain;
}
.resultsImg {
  height: auto;
  width: 90%;
  border-radius: 10px;
}
.flip {transform: translateY(180deg)}
  .polygon {
  fill:var(--stroke);
}
.chart {
  stroke:var(--stroke);
  stroke-dasharray: 700;
  stroke-dashoffset: 650;
  animation: draw 2.5s forwards;
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.afterHero {
  padding-bottom:100px;
  overflow-x: hidden;
}
.imageFill {
  z-index:-5;
  margin-top:-200px;
  opacity:0.5;
}
.slider {
  align-items:flex-start;
  display:flex;
  white-space: nowrap;
  animation: slide 20s linear infinite;
  max-width:100%;
  overflow-y: scroll;
}
.slider > img {height:50px; width:auto; display:flex;}
@keyframes slide {
    0% {
          -webkit-transform: translateX(0%);
          -ms-transform: translateX(0%);
          transform: translateX(0%);
      }
      
      100% {
          -webkit-transform: translateX(-80%);
          -ms-transform: translateX(-80%);
          transform: translateX(-80%);
      }
}
  .gear { height:45px; width:45px }
  .counter {font-size:40px; text-align:center; align-items:center;}
  .counterIcon {font-size:60px;}
  .counterWrapper {background-color:var(--counter-bg);}
  .counters {background-color: var(--counters); border-radius:20px; padding:0 50px;}




  /* RESPONSIVE                   TABLET                 RESPONSIVE                              TABLET  */

@media screen and (max-width:1000px) {

  .hero {
    padding:30px;
    border-radius:50px;
    height:auto;
    margin-top:20px;
    background-position: var(--position);
  }
  .hero h1 {
  font-family:Rosario;
  font-weight: 500;
  font-size:40px;
  letter-spacing: 2px;
  padding-right:0;
  }
  .hero p {
    padding-top:10px;
    font-size:19px;
    font-weight:500;
    padding-right: 0;
  }
  .slider {
    animation: slide 30s linear infinite;
  }
  .slider > img {height:30px; width:auto;}
  .parallax {
    padding-top:15vh;   
  }
  .bg1 {
    background-position-y:35%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment:;
    padding-top:0;
  }
  .bg2 { 
    background-size: contain;
  }
  .heroLeft {padding:0 40% 0 0;}
  .heroRight {display:none;}
  .gear { height:20px; width:20px }
  .counter {font-size:30px;} .counterIcon {font-size:45px;}
  .counters {padding:0 10px;}
  .afterHero {padding-bottom:50px;}

}

@media screen and (max-width:650px) {
  .heroLeft {padding:0 0% 0 0;}
}